import Image from 'next/image'
import React from 'react'
import { UIIcons } from 'src/constants/Icons'
import styles from './styles.module.css'

const BurgerButton = ({ handleOpenMenu }) => {
  return (
    <button type="button" className={styles.burgerBtn} onClick={handleOpenMenu}>
      <Image
        src={UIIcons.burgerHeader}
        width={22}
        height={22}
        alt="Menu"
      ></Image>
    </button>
  )
}

export default BurgerButton
