import React from 'react'
import PriceItem from './PriceItem'
import { useTranslations } from 'next-intl'
import styles from './styles.module.css'

const PriceSectionComponent = () => {
  const t = useTranslations('Landing')
  const tariffs = t.raw('tariffs')

  return (
    <div className={styles.tariffsContainer}>
      {tariffs.map((tariff, index) => (
        <PriceItem key={index} tariff={tariff} />
      ))}
    </div>
  )
}

export default PriceSectionComponent
