import React, { useContext, useState } from 'react'
import { ConfigProvider, Input, Button } from 'antd'
import styles from './styles.module.css'
import antdColors from '@/constants/colors'
import { ThemeContext } from '@/providers/ThemeProvider'
import Loading from '@/ui/components/Loading'
const { TextArea } = Input

const Feedback = () => {
  const { theme, setTheme } = useContext(ThemeContext)
  const [text, setText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hasSent, setHasSent] = useState(false)

  const handleChange = e => {
    setText(e.target.value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      })
      setText('')
      setHasSent(true)
    } catch (error) {
      console.error('Error submitting feedback:', error)
    } finally {
      setIsLoading(false)
    }
    setText('')
    setHasSent(true)
  }

  const disabled = !text

  if (isLoading) return <Loading />

  return (
    <ConfigProvider theme={antdColors[theme]}>
      <div className={styles.feedbackContainer}>
        <div className={styles.centred}>
          {!hasSent && (
            <>
              <TextArea rows={4} value={text} onChange={handleChange} />
              <Button
                className={styles.btn}
                type="primary"
                block={false}
                onClick={handleSubmit}
                disabled={disabled}
              >
                Send Feedback
              </Button>
            </>
          )}
          {hasSent && <p>Thank you for your feedback!</p>}
        </div>
      </div>
    </ConfigProvider>
  )
}

export default Feedback
