'use client'
import { Suspense, useEffect, useState } from 'react'
import styles from './page.module.css'
import EmblaCarousel from '@/ui/components/EmblaCarousel'
import Header from '@/ui/components/Header'
import '@/ui/components/EmblaCarousel/embla.css'
import { useTranslations } from 'next-intl'
import { getUserLocale } from '@/utils/locale'
import PriceSectionComponent from 'src/ui/components/PriceSectionComponent'
import Feedback from 'src/ui/components/Feedback'

export default function Home() {
  const [locale, setLocale] = useState('en')
  const t = useTranslations('Landing')
  const OPTIONS = { loop: true, autoplay: true }
  const SLIDE_COUNT = 4
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

  useEffect(() => {
    const savedLocale = getUserLocale()
    if (savedLocale) {
      setLocale(savedLocale)
    }
  }, [])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <main className={styles.main}>
        <Header />
        <section className={styles.mainSection}>
          ALFA VERSION! test VERSION
        </section>
        <section className={styles.section}>
          <div className={styles.headerContainer}>
            <h1>{t('albumSectionHeader')}</h1>
          </div>
          <EmblaCarousel slides={SLIDES} options={OPTIONS} />
        </section>
        <section className={styles.sectionTariff} id="tariffs">
          <h1 className={styles.darkBgHeader}>{t('priceSectionHeader')}</h1>
          <PriceSectionComponent />
        </section>
        <section id="#feedback" className={styles.feedbackSection}>
          <div className={styles.headerContainer}>
            <h1>{t('feedbackSectionHeader')}</h1>
          </div>
          <Feedback />
        </section>

        <div style={{ height: '400px' }}>ALFA VERSION!</div>
      </main>
    </Suspense>
  )
}
