import React from 'react'
import styles from './styles.module.css'
import CountPrice from './CountPrice'
import FeatureItem from './FeatureItem'
import { useDispatch, useSelector } from 'react-redux'
import { applyUserPlan } from '@/store/Features/usersSlice'
import { setIsTariffModalOpened } from '@/store/Features/UISlice'

const PriceItem = ({ tariff }) => {
  const dispatch = useDispatch()
  const status = useSelector(state => state.user.currentPlan.status)
  const planName = useSelector(state => state.user.currentPlan.planName)
  const handleSetTariff = () => {
    console.log('меняем тариф')
    dispatch(applyUserPlan({ plan: tariff.code, planId: tariff.planId }))
    dispatch(setIsTariffModalOpened(false))
  }

  const isCurrentPlan = tariff.code === planName
  return (
    <div className={styles.priceItemContainer}>
      <h2 className={styles.tariffName}>{tariff.name}</h2>
      <CountPrice
        currency={tariff.currency}
        perAmount={tariff.perAmount}
        amount={tariff.amount}
      />
      <div className={styles.tarifDescription}>{tariff.description}</div>
      <button
        disabled={isCurrentPlan ? true : false}
        type="button"
        className={styles.tariffBnt}
        onClick={handleSetTariff}
      >
        {isCurrentPlan ? 'Текущий план' : tariff.btnText}
      </button>
      <h3 className={styles.featuresHeader}>{tariff.featureDescription}</h3>
      <div className={styles.features}>
        {tariff.features.map(feature => (
          <FeatureItem key={feature} text={feature} />
        ))}
      </div>
    </div>
  )
}

export default PriceItem
