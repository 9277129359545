import React from 'react'
import styles from './styles.module.css'
const CountPrice = ({ currency, perAmount, amount }) => {
  return (
    <div>
      <span className={styles.currency}>{currency}</span>
      <span className={styles.amount}>{amount}</span>
      <span className={styles.currency}>/{perAmount}</span>
    </div>
  )
}

export default CountPrice
