'use client'
import React, { useState } from 'react'
import styles from './styles.module.css'
import { signIn, useSession } from 'next-auth/react'
import Link from 'next/link'
import logo from '/public/logo.svg'
import Image from 'next/image'
import LanguageDropdown from '../LanguageDropdown'
import { useTranslations } from 'next-intl'
import { UIIcons } from '@/constants/Icons'
import DrawerMenu from './DrawerMenu'
import BurgerButton from './BurgerButton'
import ThemeToggleButton from '../ThemeToggleButton'

export default function Header() {
  const [menuOpened, setMenuOpened] = useState(false)
  const t = useTranslations('Menu')
  const { data: session } = useSession()
  if (session?.user) {
    session.user = {
      name: session.user.name,
      email: session.user.email,
      image: session.user.image,
      id: session.user.id,
    }
  }

  const handleLogout = () => {
    window.location.href = '/api/auth/signout'
  }

  const handleLogin = () => {
    window.location.href = '/api/auth/signin'
  }

  // const handleSignIn = () => {
  //   window.location.href = '/registration'
  // }

  const handleClickAuthMenu = route => {
    signIn(undefined, { callbackUrl: route })
  }

  const handleOpenMenu = () => {
    console.log('menuOpened', menuOpened)
    setMenuOpened(true)
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.logo}>
        <Image src={logo} width={80} height={40} alt="kiddo memos" />
      </div>
      <nav className={styles.nav}>
        <ul className={styles.navMenu}>
          <li>
            <Link href="/">{t('main')}</Link>
          </li>
          <li>
            <Link href="/#tariffs">{t('pricing')}</Link>
          </li>
          <li>
            <Link href="/#examples">{t('examples')}</Link>
          </li>
          <li>
            <Link href="/main">
              <button type="button" className={styles.goAlbumsBtn}>
                {t('albums')}
              </button>
            </Link>
          </li>
          <li>
            <ThemeToggleButton />
          </li>
        </ul>
      </nav>

      {session ? (
        <div className={styles.profile}>
          <LanguageDropdown />
          <div className={styles.profileElement}>{session?.user?.name}</div>
          <BurgerButton handleOpenMenu={handleOpenMenu} />
        </div>
      ) : (
        <div className={styles.profile}>
          <LanguageDropdown />
          <div className={styles.logIn} onClick={handleLogin}>
            {t('login')}
          </div>
          <BurgerButton handleOpenMenu={handleOpenMenu} />
          {/* <div onClick={handleSignIn}>{t('register')}</div> */}
        </div>
      )}
      <DrawerMenu
        handleLogout={handleLogout}
        menuOpened={menuOpened}
        setMenuOpened={setMenuOpened}
        session={session}
      />
    </div>
  )
}
