import { Drawer } from 'antd'
import React, { useState } from 'react'
import styles from './styles.module.css'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
const DrawerMenu = ({ setMenuOpened, menuOpened, session, handleLogout }) => {
  const t = useTranslations('Menu')
  return (
    <Drawer
      className={styles.drawer}
      closable
      destroyOnClose
      placement="right"
      open={menuOpened}
      onClose={() => setMenuOpened(false)}
    >
      <div className={styles.drawerContentWrapper}>
        {session && (
          <div className={styles.userProfile}>
            <div className={styles.avatarContainer}>
              <Image
                src={session?.user?.image}
                alt="user avatar"
                width={50}
                height={50}
                className={styles.avatar}
              />
            </div>
            <div className={styles.username}>{session?.user?.name}</div>
          </div>
        )}
        <div className={styles.linksContainer}>
          <div className={styles.mains}>
            <div
              className={styles.mainsTop}
              onClick={() => setMenuOpened(false)}
            >
              <Link href="/">{t('main')}</Link>
              <Link href="/#tariffs">{t('pricing')}</Link>
              <Link href="/">{t('examples')}</Link>
              <Link href="/">{t('feedback')}</Link>
            </div>
            <div className={styles.mainsBottom}>
              <Link href="/main">{t('albums')}</Link>
            </div>
          </div>
          {session && (
            <div className={styles.logout} onClick={handleLogout}>
              <span className={styles.logoutText}>{t('logout')}</span>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default DrawerMenu
