import { Flex, Spin } from 'antd'
import styles from './styles.module.css'
import classNames from 'classnames'

const Loading = ({ fullsize }) => {
  return (
    <div
      className={classNames(styles.spinWrapper, {
        [styles.fullsize]: fullsize,
      })}
    >
      <Flex gap="small" vertical>
        <Spin size="large"></Spin>
      </Flex>
    </div>
  )
}

export default Loading
