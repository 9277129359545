import Image from 'next/image'
import React from 'react'
import styles from './styles.module.css'
import { UILanding } from 'src/constants/Icons'

const FeatureItem = ({ text }) => {
  return (
    <div className={styles.feature}>
      <Image
        src={UILanding.doneArrowMini}
        width={12}
        height={12}
        alt={'arrow'}
      />
      <span>{text}</span>
    </div>
  )
}

export default FeatureItem
